





































import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import SelectMultiple from '@/components/atoms/SelectMultiple.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import LoadClassOptionsApi from '@/mixins/teacher/LoadClassOptionsApi'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TabBase,
    SelectMultiple,
    ButtonBase,
    ColoredBox,
    SelectBase,
  },
})
export default class Timetable extends Mixins(LoadClassOptionsApi) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    { text: '時間割設定', active: true },
  ]

  private grades: { id: number; name: string }[] = []
  private selectedGradeId: number | null = null

  private studentsWithGrade: { text: string; value: string }[] = []
  private selectedStudentsWithGrade: string[] = []

  private dayOfWeeks: { id: number; name: string }[] = [
    { id: 1, name: '月' },
    { id: 2, name: '火' },
    { id: 3, name: '水' },
    { id: 4, name: '木' },
    { id: 5, name: '金' },
    { id: 6, name: '土' },
    { id: 7, name: '日' },
  ]
  private selectedDayOfWeekId: number | null = null

  private classes: { text: string; value: string }[] = []
  private selectedClass: string | null = null

  private studentsWithClass: { text: string; value: number }[] = []
  private selectedStudentsWithClass: number[] = []

  private isAfterSave = false

  @Watch('selectedGradeId')
  onSelectedGradeId(newGradeId: number) {
    this.loadStudentsWithGrade(newGradeId)
  }

  @Watch('selectedDayOfWeekId')
  onSelectedDayOfWeekIdChanged(newDayOfWeekId: number, oldDayOfWeekId: number | null) {
    if (
      oldDayOfWeekId !== null &&
      newDayOfWeekId !== oldDayOfWeekId &&
      this.selectedClass !== null &&
      this.selectedClass !== '' &&
      this.isAfterSave === false
    ) {
      alert('曜日を変更しても保存されません。')
    }
    this.selectedClass = null
    this.isAfterSave = false
    this.loadClasses(newDayOfWeekId)
  }

  @Watch('selectedClass')
  onSelectedClass(newSelectedClass: string | null, oldSelectedClass: string | null) {
    if (
      newSelectedClass !== null &&
      oldSelectedClass !== null &&
      oldSelectedClass !== '' &&
      newSelectedClass !== oldSelectedClass &&
      this.isAfterSave === false
    ) {
      alert('クラスを変更しても保存されません。')
    }
    this.studentsWithClass = []
    this.selectedStudentsWithClass = []
    this.isAfterSave = false
    this.loadStudentsWithClass()
  }

  mounted() {
    this.loadGrades()
  }

  /**
   * 学年情報を取得する
   * 中1, 中2, 中3, 小1, 小2...の順番になる
   */
  private async loadGrades() {
    await Vue.prototype.$http.httpWithToken.get('/grade_groups').then((res: any) => {
      this.grades = res.data
        .map((gradeGroup: { grades: { id: number; name: string }[] }) => {
          return gradeGroup.grades.map((grade: { id: number; name: string }) => {
            return { id: grade.id, name: grade.name }
          })
        })
        .flat()
    })
  }

  /**
   * 選択した学年の生徒を取得する
   */
  private async loadStudentsWithGrade(gradeId: number) {
    await Vue.prototype.$http.httpWithToken
      .get('/students', {
        params: { branchId: this.branchId, gradeId: gradeId },
      })
      .then((res: any) => {
        this.studentsWithGrade = res.data.students.map((student: any) => {
          return {
            text: student.nickname,
            value: `${student.id},${student.nickname},${student.gradeName}`, // ユーザID,氏名,学年名
          }
        })
      })
  }

  /**
   * 選択したクラス・コマの生徒を取得する
   */
  private async loadStudentsWithClass() {
    if (this.selectedClass === null || this.selectedClass === '') return

    const classInformations = this.selectedClass.split(',')
    const isGroup = classInformations[0] == 'true'
    const classSettingId = classInformations[1]
    let period = null
    if (classInformations.length > 2) {
      period = classInformations[2]
    }

    await Vue.prototype.$http.httpWithToken
      .get('/students', {
        params: { branchId: this.branchId, isGroup: isGroup, classSettingId: classSettingId, period: period },
      })
      .then((res: any) => {
        this.studentsWithClass = res.data.students.map((student: any) => {
          return {
            text: `${student.gradeName} ${student.nickname}`,
            value: student.id,
          }
        })
      })
  }

  /**
   * 授業情報取得
   */
  private async loadClasses(weekCode: number) {
    this.classes = await this.loadClassOptions(this.branchId, weekCode)
  }

  /**
   * 生徒一覧で選択した生徒を時間割設定状況に追加する
   * ただし、すでに時間割設定状況に存在する場合は二重追加しない
   */
  private addUnit() {
    if (this.selectedStudentsWithGrade.length === 0 || this.selectedClass === null) return

    this.selectedStudentsWithGrade.forEach((studentValue) => {
      const studentInformation = studentValue.split(',')
      const studentId = parseInt(studentInformation[0], 10)
      const nickName = studentInformation[1]
      const gradeName = studentInformation[2]

      if (!this.studentsWithClass.some((option) => option.value === studentId)) {
        this.studentsWithClass.push({ text: `${gradeName} ${nickName}`, value: studentId })
      }
    })

    this.isAfterSave = false
  }

  /**
   * 時間割設定状況から選択した生徒を削除する
   */
  private removeUnit() {
    if (this.selectedClass === null) return
    this.selectedStudentsWithClass.forEach((studentId) => {
      this.studentsWithClass = this.studentsWithClass.filter((option) => option.value !== Number(studentId))
    })

    this.isAfterSave = false
  }

  /**
   * 保存する
   */
  private save() {
    if (this.selectedClass === null) {
      return alert('クラスを設定してください。')
    }

    const classInformation = this.selectedClass.split(',')

    const params = {
      userIds: this.studentsWithClass.map((student) => student.value),
      isGroup: classInformation[0] === 'true',
      classSettingId: classInformation[1],
      period: classInformation.length > 2 ? classInformation[2] : null,
    }

    Vue.prototype.$http.httpWithToken.patch('/student_timetables/update_all', params).then(() => {
      alert('更新しました。')
      this.isAfterSave = true
    })
  }
}
